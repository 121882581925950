import AppLayout from "../layout/Layout";
import StatsLayout from '../layout/dashboardLayout/StatsLayout';
import LazyComponent from "./LazyComponent";
import PrivateRoute from "./protected/PrivateRoute";

export const SiteMap = {
  home: {
    title: "home",
    path: "/",
    element: (
      <AppLayout>
        <LazyComponent path="home/Home" />
      </AppLayout>
    ),
    description: "home-Page",
  },
  about: {
    title: "about",
    path: "/about",
    element: (
      <AppLayout>
        <LazyComponent path="about/AboutUs" />
      </AppLayout>
    ),
    description: "about-Page",
  },
  services: {
    title: "service",
    path: "/service",
    element: (
      <AppLayout>
        <LazyComponent path="service/Service" />
      </AppLayout>
    ),
    description: "services-Page",
  },
  pricing: {
    title: "pricing",
    path: "/pricing",
    element: (
      <PrivateRoute>
        <AppLayout>
          <LazyComponent path="pricing/Price" />
        </AppLayout>
      </PrivateRoute>
    ),
    description: "pricing-Page",
  },

  pricePacakge: {
    title: "pricePackage",
    path: "/pricePackage",
    element: (
      <PrivateRoute>
        <AppLayout>
          <LazyComponent path="pricing/PricePacakge" />
        </AppLayout>
      </PrivateRoute>
    ),
    description: "pricePacakge-Page",
  },

  contact: {
    title: "contact",
    path: "/contact",
    element: (
      <AppLayout>
        <LazyComponent path="contact/Contact" />
      </AppLayout>
    ),
    description: "contact-Page",
  },
  register: {
    title: "register",
    path: "/signup",
    element: <LazyComponent path="register/Register" />,
    description: "register-Page",
  },

  secondregister: {
    title: "seondregister",
    path: "/patientdetail",
    element:
      <PrivateRoute>
        <LazyComponent path="register/SecondRegister" />
      </PrivateRoute>,
    description: "second_register-Page",
  },

  login: {
    title: "login",
    path: "/login",
    element:
      <LazyComponent path="login/Login" />,
    description: "login-Page",
  },

  profile: {
    title: "profile",
    path: "/profile",
    element:
      <PrivateRoute>
        <LazyComponent path="profile/Profile" />
      </PrivateRoute>,
    description: "profile-Page",
  },

  forgetPassword: {
    title: "forgotPassword",
    path: "/forgot",
    element:
      <LazyComponent path="forgot/Forgot" />,
    description: "forgot-Page",
  },

  resetPassword: {
    title: "resetPassword",
    path: "/reset",
    element: <LazyComponent path="reset/Reset" />,
    description: "reset-Page",
  },

  OTP: {
    title: "OTP",
    path: "/otp",
    element:
      <LazyComponent path="otp/OTP" />,
    description: "OTP-Page",
  },

  dashboard: {
    title: "dashboard",
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <StatsLayout>
          <LazyComponent path="dashboard/Dashboard" />
        </StatsLayout>
      </PrivateRoute>
    ),
    description: "dashboard-Page",
  },


  visualDashboard: {
    title: "visualdashboard",
    path: "/visual",
    element: (
      <PrivateRoute>
        <StatsLayout>
          <LazyComponent path="dashboard/VisualDashboard" />
        </StatsLayout>
      </PrivateRoute>
    ),
    description: "dashboard-Page",
  },

  visualDashboardHealthTable: {
    title: "HealthHistory",
    path: "/healthHistory",
    element: (
      <PrivateRoute>
        <StatsLayout>
          <LazyComponent path="dashboard/rightSideSection/HealthHistory" />
        </StatsLayout>
      </PrivateRoute>
    ),
    description: "dashboard-Page",
  },

  symptomDashboard: {
    title: "symptomdashboard",
    path: "/symptom",
    element: (
      <PrivateRoute>
        <StatsLayout>
          <LazyComponent path="dashboard/VisualSymptom" />
        </StatsLayout>
      </PrivateRoute>
    ),
    description: "dashboard-Page",
  },
}
