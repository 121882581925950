
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isHovered: false,
};

const hoverSlice = createSlice({
  name: "hover",
  initialState,
  isHovered: false,
  reducers: {

    hover: state => {
      state.isHovered = true;
    },
    // New reducer for unhover
    unhover: state => {
      state.isHovered = false;
    }
  }

});

export const { hover, unhover, isHovered } = hoverSlice.actions;



export default hoverSlice.reducer;
