import React from 'react';

const ErrorScreen = () => {
  return (
    <div>
      <h1>404</h1>
      <p>Page Not Found</p>
      <button><a href="/">Go Home</a></button>
    </div>
  )
}

export default ErrorScreen
