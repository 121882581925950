export const global = {
  primaryColor: "#00672F",
  secondaryColor: "#1C1F34",
  tertiaryColor: "#737373",
  textColor: "#252B42",
  whiteColor: "#FFFFFF",
  lightFontColor: "#ffffffb7",
  bgLightColor: "#EEEEEE",

  // dashboard color theme

  primaryBlack: "#1C1F34",
  secondaryBlack:'#343434',


  shadow:
    "0px 0px 10px 5px rgba(0, 0, 0, 0.041),0px 1px 3px 0px rgba(0, 0, 0, 0.041),0px 1px 20px 5px rgba(0, 0, 0, 0.041)",

  // font family
  fontCormorant: '"Cormorant",serif',
  fontMontserrat: '"Montserrat", sans-serif',
  fontManrope: '"Manrope", sans-serif',

  // media query
  large: "1200px",
  medium: "900px",
  small: "600px",
  mobile: "400px",
};
