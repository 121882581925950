import { createSlice } from '@reduxjs/toolkit';

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        cards: [],
        showAddCard: true,
        subscriptions: [],
        yearlyPriceId: null,
        monthlyPriceId: null,
        subscriptionId: null,
    },
    reducers: {
        setCards: (state, action) => {
            state.cards = action.payload;
        },
        setShowAddCard: (state, action) => {
            state.showAddCard = action.payload;
        },
        setSubscriptions: (state, action) => {
            state.subscriptions = action.payload;
        },
        setYearlyPriceId: (state, action) => {
            state.yearlyPriceId = action.payload;
        },
        setMonthlyPriceId: (state, action) => {
            state.monthlyPriceId = action.payload;
        },
        setSubscriptionId: (state, action) => {
            state.subscriptionId = action.payload;
        },
    },
});

export const {
    setCards,
    setShowAddCard,
    setSubscriptions,
    setYearlyPriceId,
    setMonthlyPriceId,
    setSubscriptionId,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
