import React from 'react';
import Button from '@mui/material/Button';
import useHeader from '../../constants/headerMenuList';
import { MobileContainer } from '../../services/header';
import { useLocation } from 'react-router-dom';
import AuthButton from './authButton';
import { sidebarMenu } from '../../constants/statsMenu';



const MobileSideBar = ({ toggleDrawer }) => {
    const { menuItems, handleNavigation } = useHeader();
    const location = useLocation();

    const handleMenuItemClick = (path, id) => {
        handleNavigation(path);
        toggleDrawer(false);
    };

    const allowedPaths = ['/dashboard', '/visual', '/symptom'];

    return (
        <MobileContainer
            role="presentation"
            onClick={() => toggleDrawer(false)}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    toggleDrawer(false);
                }
            }}
        >
            <AuthButton className="mobile_auth_button" handleNavigation={handleNavigation} toggleDrawer={toggleDrawer} />
            {menuItems.map((item) => (
                <Button
                    key={item.id}
                    className={`mobile_menu ${item.path === location.pathname ? 'active' : ''}`}
                    onClick={() => handleMenuItemClick(item.path, item.id)}
                    sx={{ display: 'block' }}
                >
                    {item.label}
                </Button>
            ))}

            <div style={{ height: '1px', width: '100%', backgroundColor: '#ffffffb7' }}></div>

            {allowedPaths.includes(location.pathname) && (
                <>
                    {sidebarMenu.map((item) => (
                        <Button
                            key={item.id}
                            className={`mobile_menu ${item.path === location.pathname ? 'active' : ''}`}
                            onClick={() => handleMenuItemClick(item.path, item.id)}
                            sx={{ display: 'block' }}
                        >
                            {item.name}
                        </Button>
                    ))}
                </>
            )}

        </MobileContainer>
    );
};

export default MobileSideBar;
