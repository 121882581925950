import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggle: state => {
      state.isOpen = !state.isOpen;
    },
    open: state => {
      state.isOpen = true;
    },
    close: state => {
      state.isOpen = false;
    }
  },
});

// Action creators are generated for each case reducer function
export const { toggle, open, close } = modalSlice.actions;

export default modalSlice.reducer;
