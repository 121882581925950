import React from "react";
import { Layout } from "antd";
import AppFooter from "./footer/Footer";
import AppHeader from "./header/Header";

const { Header, Content, Footer } = Layout;

const AppLayout = ({ children }) => {
    return (
        <Layout
            style={{ minHeight: "100vh"}}
        >
            <Header
                style={{
                    padding: 0,
                    backgroundColor: "#FFFFFF",
                }}
            >
                <AppHeader />
            </Header>

            <Layout
            >
                <Content
                    style={{ background: "#FFFFFF",
                        paddingTop:"1.5rem" }}
                >
                    {children}
                </Content>
                <Footer style={{ padding: 0 }}>
                    <AppFooter />
                </Footer>
            </Layout>
        </Layout>
    )
}

export default AppLayout;
