import React from 'react';
import { FooterContainer } from '../../services/footer';
import { Typography, Box, Stack } from '@mui/material';
import { socialIcon, footerList } from '../../constants/footerData';
import { images } from '../../assets/images';

const Footer = () => {
  return (
    <FooterContainer>
      <Box className="footer_top">
        <Box className="footer_logo_img">
          <img className="footer_logo" src={images.bodyMapper_green_logo} alt="" />
        </Box>

        <Stack direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          className="footer_list"
        >
          {
            footerList.map((item) => (
              <Box className="footer_list_box" key={item.id}>
                <Typography className='list_title'>{item.title}</Typography>
                {item.list.map((list, index) => (
                  <Box key={index}>
                    {item.id === 5 && index < 3 ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {footerList[4].icons[index]}
                        <Typography className='list'>{list}</Typography>
                      </Box>
                    ) : (
                      <Typography className='list' key={index}>
                        {list}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            ))
          }
        </Stack>
      </Box>
      <Box className="footer_bottom">
        <Typography className="reserved_title">Made With Love By Figmaland All Right Reserved </Typography>
        <Box className="social_icon">
          {socialIcon.map((item) => (
            <Box className="icon" key={item.id}>
              {item.icon}
            </Box>
          ))}
        </Box>
      </Box>
    </FooterContainer>
  );
};

export default Footer;
