import { createSlice } from '@reduxjs/toolkit';

const querySlice = createSlice({
    name: 'query',
    initialState: {
        queryId: null,
    },
    reducers: {
        setQueryId: (state, action) => {
            state.queryId = action.payload;
        },
        clearQueryId: (state) => {
            state.queryId = null;
        },
    },
});

export const { setQueryId, clearQueryId } = querySlice.actions;

export default querySlice.reducer;