import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    editId: null,
};

const editSlice = createSlice({
    name: 'edit',
    initialState,
    reducers: {
        setEditId: (state, action) => {
            state.editId = action.payload;
        },
        clearEditId: (state) => {
            state.editId = null;
        },
    },
});

export const { setEditId, clearEditId } = editSlice.actions;

export default editSlice.reducer;
