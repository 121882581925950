import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideBarContainer } from '../../services/sidebarStyled';
import { images } from '../../assets/images';
import { Box, Typography } from '@mui/material';
import { sidebarMenu, sideBottomMenu } from '../../constants/statsMenu';
import AuthButton from '../header/authButton';
import { logout } from '../../redux/reduxSlice/authSlice';

const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const currentPath = location.pathname;

    const getActiveTab = () => {
        for (const item of [...sidebarMenu, ...sideBottomMenu]) {
            if (item.path === currentPath) {
                return item.name;
            }
        }
        return sidebarMenu[0]?.name;
    };

    const activeTab = getActiveTab();

    const handleNavigation = (path, name) => {
        navigate(path); 
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    return (
        <SideBarContainer>
            <Box className="inner_section">
                <Box className="top">
                    <Box className="user_profile">
                        <AuthButton handleNavigation={handleNavigation} />
                    </Box>
                    <Box className="line_bar"> </Box>
                    <img className='bars' src={images.bars} alt="" />
                </Box>

                <Box className="bar_tabs">
                    {sidebarMenu.map((item) => (
                        <Box
                            className={`bar_tab ${activeTab === item.name ? 'active' : ''}`}
                            key={item.name}
                            onClick={() => handleNavigation(item.path, item.name)}
                        >
                            <img className='tab_icon' src={item.icon} alt="" />
                            <Typography className='tab_title'>{item.name}</Typography>
                        </Box>
                    ))}
                    <Typography className="bar_title">ACCOUNT</Typography>
                    {sideBottomMenu.map((item) => (
                        <Box
                            className={`bar_tab ${activeTab === item.name ? 'active' : ''}`}
                            key={item.name}
                            onClick={item.name === 'Logout' ? handleLogout : () => handleNavigation(item.path, item.name)}
                        >
                            <img className='tab_icon' src={item.icon} alt="" />
                            <Typography className='tab_title'>{item.name}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </SideBarContainer>
    );
};

export default Sidebar;
