import styled from "styled-components";

export const SideBarContainer = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.primaryColor};
  display: flex;
  justify-content: center;
  .inner_section {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;
      .bar_logo {
        font-size: 2rem;
        text-align: center;
        color: ${({ theme }) => theme.whiteColor};
        padding: 1rem 0;
        .bar_logo_link {
          color: ${({ theme }) => theme.whiteColor};
          text-decoration: none;
        }
      }
      .bars {
        cursor: pointer;
        width: 40px;
        padding: 1rem 0 0 4px;
      }
      .line_bar {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid ${({ theme }) => theme.whiteColor};
      }
    }

    .bar_tabs {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .bar_tab {
        padding: 0.5rem 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 1rem;
        .tab_icon {
          width: 25px;
        }
        .tab_title {
          color: ${({ theme }) => theme.whiteColor};
        }
      }
      .bar_tab:hover {
        background-color: #33a968a1;
      }

      .bar_tab.active {
        background-color: #33a968a1;
      }
        
      .bar_title {
        padding: 0 2rem;
        color: ${({ theme }) => theme.whiteColor};
        font-family: ${({ theme }) => theme.fontCormorant};
      }
    }

    .user_profile {
      padding: 0.5rem;
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;
