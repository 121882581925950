import styled from "styled-components";

export const HeaderContainer = styled.div`
  .header_appbar {
    position: fixed;
    top: 0;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: none;
    .header_toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.2em 2em;
      .header_logo {
        margin-top: 1rem;
        height: 80px;
        width: 110px;

        img {
          height: 100%;
          width: 100%;
        }

        @media screen and (max-width: 900px) {
          margin: 0;
          height: 50px;
          width: 100px;
        }

        @media screen and (max-width: 600px) {
          margin: 0;
          height: 50px;
          width: 100px;
        }
      }

      .header_menu {
        background-color: ${({ theme }) => theme.primaryColor};
        padding: 0.6em 2em;
        border-radius: 1.5rem;
        display: flex;
        align-items: center;
        gap: 2em;
        .header_menu_item {
          color: ${({ theme }) => theme.lightFontColor};
          text-transform: capitalize;
          font-size: 1rem;
          cursor: pointer;
          font-family: ${({ theme }) => theme.fontManrope};
        }

        .header_menu_item:hover,
        .header_menu_item.active {
          color: ${({ theme }) => theme.whiteColor};
        }

        @media screen and (max-width: 901px) {
          display: none;
        }
      }

      .header_signin_button {
        background-color: ${({ theme }) => theme.primaryColor};
        color: ${({ theme }) => theme.whiteColor};
        border-radius: 1rem;
        padding: 0.5em 2em;
        text-transform: none;
        font-family: ${({ theme }) => theme.fontManrope};
        font-size: 1rem;

        @media screen and (max-width: 901px) {
          display: none;
        }
      }

      .header_menu_button {
        display: none;

        .menu_icon {
          font-size: 2rem;
        }

        @media screen and (max-width: 902px) {
          display: block;
          color: ${({ theme }) => theme.whiteColor};
          background-color: ${({ theme }) => theme.primaryColor};
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.6rem;
          
        }
      }

      @media screen and (max-width: 900px) {
        margin: 0.4em 0;
      }

      @media screen and (max-width: 901px) {
        .bm_user {
          display: none;
        }
      }
    }

    // @media screen and (max-width: 901px) {
    //   background-color: transparent;
    // }
  }
`;

export const MobileContainer = styled.div`
  width: 220px;
  height: 111vh;
  background-color: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.whiteColor};

  .bm_user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.lightFontColor};
  }
  .mobile_menu {
    color: ${({ theme }) => theme.whiteColor};
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: bold;
    margin: 1rem;
  }
  .mobile_auth_button {
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.lightFontColor};
    padding: 0.4em 4em;
  }
  @media screen and (min-width: 902px) {
    display: none;
  }
`;
