import { createSlice } from "@reduxjs/toolkit";

const commentsSlice = createSlice({
  name: "comments",
  initialState: [],
  reducers: {
    addComment: (state, action) => {
      console.log(action.payload,
        'action.payload'
      )
      state.push(action.payload);
    },
    // deleteComment: (state, action) => {
    //   const commentId = action.payload;
    //   return state.filter(comment => comment.id !== commentId);
    // },
    // updateComment: (state, action) => {
    //   const { id, data } = action.payload;
    //   console.log(data,'data')
    //   const existingComment = state.find(comment => comment.id === id);
    //   console.log(existingComment,'existingComment')
    //   if (existingComment) {
    //     Object.assign(existingComment, data);
    //   }
    // },
   
  },
});

export const { addComment, deleteComment, updateComment } = commentsSlice.actions;

export default commentsSlice.reducer;
