import React from "react";
import { Layout } from "antd";
import AppSidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import useMediaQuery from "@mui/material/useMediaQuery";

const { Sider, Content } = Layout;

const StatsLayout = ({ children }) => {
    const isMobile = useMediaQuery('(max-width:900px)');

    return (
        <Layout>
            {isMobile ? (
                <Header />
            ) : (
                <Sider
                >
                    <AppSidebar />
                </Sider>
            )}
            <Layout style={{backgroundColor:"white"}}>
                <Content>{children}</Content>
            </Layout>
        </Layout>
    );
};

export default StatsLayout;
