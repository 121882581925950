import { useNavigate } from "react-router-dom";
import { SiteMap } from "../navigation/SiteMap";

export default function useHeader() {
  const navigate = useNavigate();

  const menuItems = [
    {
      id: 1,
      label: "Home",
      path: SiteMap.home.path,
    },
    {
      id: 2,
      label: "About",
      path: SiteMap.about.path,
    },
    {
      id: 3,
      label: "Services",
      path: SiteMap.services.path,
    },
    {
      id: 4,
      label: "Pricing",
      path: SiteMap.pricing.path,
    },
    {
      id: 5,
      label: "Contact",
      path: SiteMap.contact.path,
    },
    // {
    //   id: 6,
    //   label: "Stats",
    //   path: SiteMap.dashboard.path,
    // }
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  return { menuItems, handleNavigation };
}
