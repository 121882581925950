import React from "react";
import { store, persistedStore } from "./redux/store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation/Router";
import { ThemeProvider } from "styled-components";
import { global } from "./style/global";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();


function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <ThemeProvider theme={global}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;