import React, { useState, useEffect } from 'react';

const getRandomColor = () => {
    const colors = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B'];
    return colors[Math.floor(Math.random() * colors.length)];
};

const Avatar = ({ name }) => {
    const [backgroundColor, setBackgroundColor] = useState(() => {
        const storedColor = localStorage.getItem('avatarBackgroundColor');
        return storedColor || getRandomColor();
    });

    useEffect(() => {
        localStorage.setItem('avatarBackgroundColor', backgroundColor);
    }, [backgroundColor]);

    const initials = name ? name.split(' ').map(word => word[0].toUpperCase()).join('') : '';

    return (
        <div
            style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#FFFFFF',
                fontSize: '1.5rem',
                textTransform: 'uppercase'
            }}
        >
            {initials}
        </div>
    );
};

export default Avatar;
