import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isLoggedIn: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    updateUserDetails: (state, action) => {
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload,
        };
      }
    },
    logoutUser: (state) => {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
});

export const { setUser, updateUserDetails, logoutUser } = authSlice.actions;

export default authSlice.reducer;

export const registerUser = (userData) => async (dispatch) => {
  try {
    dispatch(setUser(userData));
  } catch (error) {
    console.error("Registration failed:", error.message);
    throw error;
  }
};

export const loginUser = (userData) => async (dispatch) => {
  try {
    dispatch(setUser(userData));
  } catch (error) {
    console.error("Login failed:", error.message);
    throw error;
  }
};
export const logout = () => async (dispatch) => {
  try {
    localStorage.clear();
    dispatch(logoutUser());
  } catch (error) {
    console.error("Logout failed:", error.message);
    throw error;
  }
};
