import { createSlice } from '@reduxjs/toolkit';

const selectedRowIdSlice = createSlice({
    name: 'selectedId',
    initialState: null,
    reducers: {
        setSelectedId: (state, action) => action.payload,
    },
});

export const { setSelectedId } = selectedRowIdSlice.actions;
export default selectedRowIdSlice.reducer;