import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formDataArray: [],
};

export const healthHistorySlice = createSlice({
  name: 'healthHistory',
  initialState,
  reducers: {
    saveFormData: (state, action) => {
      const { fileUploaded, documentInfo, ...formDataWithoutFile } = action.payload;
      state.formDataArray = [
        ...state.formDataArray,
        { ...formDataWithoutFile, fileUploaded: !!fileUploaded, documentInfo }
      ];
    },

    clearFormData: (state) => {
      state.formDataArray = [];
    },
  },
});

export const { saveFormData, clearFormData } = healthHistorySlice.actions;

export const selectFormDataArray = (state) => state.healthHistory.formDataArray;
export default healthHistorySlice.reducer;
