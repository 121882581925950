import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pins: [],
    pinId: null,
    selectedPinId: null,
};

const pinSlice = createSlice({
    name: 'pin',
    initialState,
    reducers: {
        setPins: (state, action) => {
            state.pins = action.payload;
        },
        setPinId: (state, action) => {
            state.pinId = action.payload;
        },
        setSelectedPinId: (state, action) => {
            // console.log('setSelectedPinId called with:', action.payload);
            state.selectedPinId = action.payload;
        },
    },
});

export const { setPins, setPinId, setSelectedPinId } = pinSlice.actions;
export default pinSlice.reducer;
