import { createSlice } from '@reduxjs/toolkit';

const pricePackageSlice = createSlice({
  name: 'pricePackage',
  initialState: null,
  reducers: {
    setPackageData: (state, action) => action.payload,
    clearPackageData: () => null,
  },
});

export const { setPackageData, clearPackageData } = pricePackageSlice.actions;
export default pricePackageSlice.reducer;
