import { createSlice } from '@reduxjs/toolkit';

const diarySlice = createSlice({
    name: 'diary',
    initialState: {
        selectedDiaryId: null,
    },
    reducers: {
        setSelectedDiaryId: (state, action) => {
            state.selectedDiaryId = action.payload;
        },
    },
});

export const { setSelectedDiaryId } = diarySlice.actions;
export default diarySlice.reducer;
